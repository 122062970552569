exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-function-color-gradient-js": () => import("./../../../src/pages/function/color-gradient.js" /* webpackChunkName: "component---src-pages-function-color-gradient-js" */),
  "component---src-pages-function-index-js": () => import("./../../../src/pages/function/index.js" /* webpackChunkName: "component---src-pages-function-index-js" */),
  "component---src-pages-function-password-generator-js": () => import("./../../../src/pages/function/password-generator.js" /* webpackChunkName: "component---src-pages-function-password-generator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-costmma-bot-js": () => import("./../../../src/pages/portfolio/costmma-bot.js" /* webpackChunkName: "component---src-pages-portfolio-costmma-bot-js" */),
  "component---src-pages-portfolio-fancy-world-js": () => import("./../../../src/pages/portfolio/fancy-world.js" /* webpackChunkName: "component---src-pages-portfolio-fancy-world-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-keep-launcher-js": () => import("./../../../src/pages/portfolio/keep-launcher.js" /* webpackChunkName: "component---src-pages-portfolio-keep-launcher-js" */),
  "component---src-pages-portfolio-maze-runner-js": () => import("./../../../src/pages/portfolio/maze-runner.js" /* webpackChunkName: "component---src-pages-portfolio-maze-runner-js" */),
  "component---src-templates-project-details-js": () => import("./../../../src/templates/project-details.js" /* webpackChunkName: "component---src-templates-project-details-js" */)
}

